.col-tabs {
  // .form-row {
  //   align-items: flex-end !important;
  //   display: flex;
  .form-row {
    margin: 0 -5px;
  }
  // }
  .traco-agencia-digito {
    line-height: 1.2em;
    position: relative;
    top: 3px;
    left: 1px;
    font-size: 20px;
  }
  .col-input-agencia {
    .form-row {
      [class*='col-'] {
        padding: 0 5px;
      }
    }
  }
  .react-tabs__content-holder {
    width: calc(100% - 112px) !important;
  }
  .ant-checkbox-wrapper {
    span {
      font-size: 11px;
      font-weight: 400;
      font-style: normal;
      color: #435c6c;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1ab394;
    border-color: #1ab394;
  }
  .item-convenio {
    // align-items: end;
    gap: 20px;

    .container-reconsulta {
      display: flex;
      align-items: center;
      padding-top: 5px;
    }

    .reconsulta-button {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 32px;
      background-color: #26417B;
      border: 1px solid #1E3361;
      color: white;
      gap: 5px;
      svg {
        margin-top: 0px;
      }
    }

  }

  .select-dados-convenio {
    .ant-select-item-option-content {
      span {
        text-align: left !important;
      }
    }
  }

  .form-row {
    .ant-form-item-label > label {
      font-size: 13px;
    }
    .col,
    [class*='col-'] {
      padding-right: 10px;
      padding-left: 10px;
    }
    padding: 0 5px;
    .flex-1 {
      flex: 1;
    }
  }
  .bureaux-charts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
    .slick-slider {
      max-width: 500px;
      @media (max-width: 1300px) {
        max-width: 400px;
      }
      @media (max-width: 1150px) {
        max-width: 300px;
      }
      position: relative;
      .slick-arrow {
        transform: translateY(-50%);
        border: 1px solid #9a9a9a;
        padding: 6px 4px;
        border-radius: 3px;
        svg {
          path {
            fill: #bcbcbc !important;
            stroke: #bcbcbc;
            stroke-width: 100px;
            stroke-linejoin: round;
          }
        }
        &:hover {
          svg {
            path {
              fill: #999999 !important;
              stroke: #999999;
            }
          }
        }
      }
      .slick-list {
        overflow: hidden;
        padding-bottom: 10px;
        .slick-slide {
          width: 33%;
          display: inline-block;
          .card-chart {
            padding: 12px;
            padding-bottom: 15px;
            background-color: #f0f0f0;
            border: none;
            box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            .card-chart-title {
              font-size: 13px;
              line-height: 1.2em;
              margin-bottom: 4px;
            }
            .card-chart-subtitle {
              font-size: 13px;
              margin-bottom: 4px;
              b {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .card-chart {
      border: 0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #676a6c;
      margin: 7px;
      .date {
        font-size: 13px;
        margin-top: 30px;
      }
      .semi-donut {
        width: 86px;
        height: 43px;
        font-size: 18px;
        line-height: 20px;
        margin-top: 15px;
        &:after {
          content: '';
          width: 86px;
          height: 86px;
          border: 14px solid;
        }
      }
    }
  }
  .options {
    .ant-checkbox-wrapper {
      text-transform: uppercase;
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 11px;
          height: 11px;
          border-radius: 0px;
          border: 1px solid #435c6c;
        }
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: #1ab394;
            background-color: #1ab394;
            position: relative;
            &::after {
              transform: rotate(45deg) scale(0.8) translate(-50%);
              top: 0%;
              left: 40%;
            }
          }
        }
      }
      .ant-checkbox + span {
        font-size: 11px;
        color: #435c6c;
        position: relative;
        top: -1px;
        padding-left: 5px;
      }
    }
  }
  label {
    margin-bottom: 6px;
    line-height: 1em;
    padding-bottom: 0px;
    color: #686b6d;
    font-size: 13px;
    margin-top: -1px;
  }
  .ant-form-item {
    margin-bottom: 15px;
    .ant-form-item-control-input-content {
      line-height: 1em;
    }
    .ant-form-item-label {
      margin-bottom: 6px;
      line-height: 1em;
      padding-bottom: 0px;
      overflow: initial;
      label {
        margin: 0px;
      }
    }
  }
  .tooltip-info {
    position: relative;
    top: 0px;
    margin-left: 5px;
    &.rezise{
      margin: 0;
    }
  }
}

.historico-form {

  .icon-edit {
    width: 10px;
    height: 10px;
    color: #263659;
  }

  .topo {
    margin-bottom: 10px;
    button.btn-validar-luz {
      background-color: #26417b;
      padding: 4px 10px;
      padding-top: 5px;
      color: #ffffff;
      font-size: 13.5px;
      font-weight: 500;
      text-wrap: nowrap;
      letter-spacing: 0.04em;
      height: auto;
      display: inline-block;
      svg {
        width: 15px;
        margin-right: 7px;
        position: relative;
        top: -1px;
      }
    }
    .select-status {
      display: inline-block;
      margin: 0px;
      position: relative;
      margin-left: 15px;
      color: #26417b;
      font-size: 14px;
      width: auto !important;
    }
    .ant-radio-group {
      margin-left: 22px;
      label.ant-radio-wrapper {
        margin-right: 10px;
        .ant-radio {
          .ant-radio-inner {
            border-color: #e5e6e7;
            width: 16px;
            height: 16px;
            &:after {
              width: 9.5px;
              height: 9.5px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: #1bb394;
            }
          }
          &.ant-radio-checked {
            .ant-radio-inner {
              border-color: #1bb394;
            }
          }
        }
        & > span:last-child {
          color: #435c6c;
          font-size: 12px;
          text-transform: uppercase;
          padding-left: 5px;
        }
        &:hover > span:last-child {
          opacity: 0.8;
        }
      }
    }
  }
  .header-calculo-luz-dia {
    margin-bottom: 10px;
  }
  .table-historico {
    width: 100%;
    .ant-table-wrapper {
      width: 100%;
    }

    .ant-table-expanded-row,
    .ant-table-expanded-row-level-1 {
      .ant-table-cell {
        background-color: #fff;
      }
    }
    .ant-table {
      table {
        .ant-table-tbody {
          tr.ant-table-row {
            td.ant-table-cell {
              &:nth-child(4) {
                min-width: 130px;
              }
            }
          }
        }
        .ant-table-row-level-0:nth-child(odd) {
          background-color: #f7f7f7;
        }
      }
    }
  }
  .ant-table {
    border: 1px solid #d9d9d9;
    margin-bottom: 15px;
    .ant-table-content {
      overflow-x: auto;
    }
    table {
      .ant-table-thead {
        tr {
          th.ant-table-cell {
            padding: 9px 14px;
            background-color: #ffffff;
            font-size: 13px;
            font-weight: 600;
            color: #646b70;
            text-align: center;
            cursor: default;
          }
        }
      }
      .ant-table-tbody {
        tr.ant-table-row {
          &.ant-table-row-level-0.striped {
            background-color: #f7f7f7;
          }
          &.ant-table-row-level-0.debito-ignorado {
            td.ant-table-cell {
              text-decoration: line-through;
              .switch-red-if-off:not(.ant-switch-checked) {
                background-color: #c4c4c4 !important;
              }
            }
          }
          &.ant-table-row-level-0 {
            cursor: pointer;
          }
          td.ant-table-cell {
            padding: 7.5px 10px;
            line-height: 1.15em;
            font-size: 13px;
            color: #686b6d;
            text-align: center;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              text-align: right;
            }
            .ant-switch {
              display: inline-block;
              height: 12px;
              min-width: 27px;
              width: 27px;
              .ant-switch-handle {
                width: 12px;
                height: 12px;
                top: 0px;
                left: -1px;
                &:before {
                  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
                }
              }
              &.ant-switch-checked {
                .ant-switch-handle {
                  right: 0px !important;
                  left: auto !important;
                }
              }
            }
            &.cell-icon-anexo {
              svg {
                width: 15px;
                height: 15px;
              }
            }
            &.ant-table-row-expand-icon-cell {
              button.btn-expanded-row {
                height: auto;
                padding: 2px 3px;
                line-height: 1em;
                transition: 0.2s;
                svg {
                  position: relative;
                  top: 1px;
                  width: 15px;
                  height: 15px;
                  transition: 0.2s;
                  path {
                    transition: 0.2s;
                  }
                }
                &.expanded-open {
                  svg {
                    transform: rotate(90deg);
                    transition: 0.2s;
                    path {
                      fill: #445566 !important;
                      transition: 0.2s;
                    }
                  }
                }
                &:hover {
                  opacity: 0.7;
                  transition: 0.2s;
                }
              }
            }
          }
          .icon-table-text {
            margin-left: 5px;
            margin-right: 15px;
          }
        }
        tr.ant-table-expanded-row {
          .topo-aba {
            margin-bottom: 20px;
            text-align: right;
            h3.titulo-aba {
              color: #445566;
              font-size: 14px;
              text-transform: uppercase;
              text-align: left;
              font-weight: 600;
            }
            .ant-popover-message-title {
              color: #222 !important;
            }
            .btn-remover-fatura {
              background-color: #ed5565;
              color: #ffffff;
              text-transform: uppercase;
              display: inline-block;
              letter-spacing: 0.04em;
              font-size: 14px;
              padding: 5px 13px;
              box-shadow: none;
              svg {
                width: 12px;
                height: 12px;
                margin-right: 8px;
              }
            }
          }
          .comprovante-pagamento {
            padding: 0px;
            padding-left: 0px;
            padding-right: 10px;
            text-align: left;
            & > label {
              margin-left: 1px;
              line-height: 1em;
              position: relative;
              top: -4px;
              margin-bottom: 0px;
            }
            .box {
              border: 1px solid #d9d9d9;
              padding: 16px 17px;
              position: relative;
              background-color: #f3f4f5;
              top: -2px;
              .picture-card {
                border: 1px dashed rgba(38, 54, 89, 0.37);
                margin: 0px;
                width: 100%;
                height: 130px;
                transition: 0.2s;
                position: relative;

                .clique-anexar {
                  position: relative;
                  top: 50%;
                  transform: translateY(-50%);
                  text-align: center;
                  padding: 0 7px;
                  font-size: 13px;
                  color: #676a6c;
                  line-height: 1.4em;

                  svg {
                    margin-bottom: 8px;
                    path {
                      fill: #676a6c !important;
                    }
                  }
                }
                .icon-document {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    width: 30px;
                    height: 30px;
                  }
                }

                .thumbnail {
                  position: relative;
                  height: 100%;
                  cursor: pointer;
                  &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  }
                  object {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                  &:hover {
                    border-color: rgba(38, 54, 89, 0.8);
                    opacity: 0.7;
                    transition: 0.2s;
                  }
                }
              }
            }
            .checkbox-validado {
              text-align: left;
              position: relative;
              top: -1px;
              .ant-checkbox-wrapper {
                margin-bottom: 0px;
                .ant-checkbox {
                  .ant-checkbox-inner {
                    width: 12px;
                    height: 12px;
                    border-radius: 0px;
                    &:after {
                      width: 5px;
                      height: 8px;
                      border-width: 1px;
                      top: 48%;
                      left: 19%;
                    }
                  }
                }
                & > span:last-child {
                  font-size: 11px;
                  margin: 0px;
                  margin-left: 5px;
                  padding: 0px;
                  color: #435c6c;
                  transition: 0.2s;
                }
                &:hover > span:last-child {
                  opacity: 0.7;
                  transition: 0.2s;
                }
              }
              .ant-checkbox-wrapper:hover .ant-checkbox-inner,
              .ant-checkbox:hover .ant-checkbox-inner,
              .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: #1bb394 !important;
              }
            }
          }
        }
      }
    }
  }
  button.btn-adicionar-fatura {
    background-color: #26417b;
    padding: 7px 16px;
    height: auto;
    color: #ffffff;
    font-size: 14.5px;
    font-weight: 500;
    letter-spacing: 0.04em;
    svg {
      width: 15px;
      margin-right: 8px;
    }
  }
  .header-historicoCia {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .limite-parcela-limite-cliente-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    justify-content: flex-end;
  }

  .limite-parcela {
    color: #686B6D;
    font-family: "Open Sans", sans-serif;
      span {
        margin-left: 5px;
        font-weight: 600;
      }

  }

  .limite-cliente {
    display: flex;
    align-items: center;
    color: #686b6d;

    font-family: 'Open Sans';

    span {
      margin-left: 5px;
      font-weight: 600;
    }
  }
}

.valida-luz-em-dia-energia-tradicional-container {
  display: flex;
  align-items: center;
}

.tabela-chamada {
  margin-top: -15px;
}

.form-upload-documentos-loja {
  border: 1px dashed #d9d9d9;
  padding: 23px 23px;
  padding-right: 10px;
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    width: 145px;
    height: 155px;
    margin: 0 20px 20px 0;
    &:hover {
      border-color: #455667;
    }
  }
  .ant-upload-list-picture-card-container {
    height: auto;
    p.data-upload-item {
      color: #495057;
      opacity: 0.8;
      text-align: center;
      width: 100%;
      line-height: 1em;
      margin-top: 6px;
      font-size: 13px;
      display: inline-block;
    }
    .ant-upload-list-item {
      margin-bottom: 9px;
      height: 155px;
    }
    .titulo-arquivo {
      background-color: #263659;
      border-radius: 2px;
      padding: 0 4px;
      height: 50px;
      position: relative;
      h5 {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.03em;
        text-align: center;
        color: #fff !important;
        position: relative;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .icone-arquivo {
      cursor: pointer;
      .ant-upload-list-item {
        .ant-upload-list-item-info {
          .ant-upload-span {
            .ant-upload-list-item-thumbnail {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .ant-upload-list-item-actions {
          a {
            opacity: 1 !important;
          }
        }
      }
    }
  }
  .ant-upload {
    .ant-upload-list-item {
      padding: 0px;
    }
    .anticon-plus {
      margin-bottom: 20px;
      svg {
        width: 30px;
        height: 30px;
      }
      svg path {
        fill: #445566;
      }
    }
    .action-anexar {
      font-size: 14px;
      color: #686b6d;
      line-height: 1.1em;
      padding: 0 20px;
    }
  }
}

.btnReenvio {
  background-color: white;
  color: #26417b;
  width: auto;
  height: 24px;
  padding: 0px 10px;
  margin: 5px 0px 0px 5px;
  text-transform: uppercase;
  border-color: #26417b;

  svg {
    width: 9px;
    margin-right: 6px;
    position: relative;
    top: -2px;
  }

  &:hover,
  &:focus {
    color: #fff;
    background-color: #26417b;
  }

  &:hover svg path,
  &:focus svg path {
    fill: #fff !important;
  }
}

.btnDeleteInputs {
  background-color: #ed5565;
  color: white;
  width: auto;
  height: 32px;
  padding-left: 22px;
  padding-right: 22px;
  margin: 0px;
  float: right;
  line-height: 1.2em;
  text-transform: uppercase;
  outline: none !important;
  svg {
    width: 13px;
    margin-right: 6px;
    position: relative;
    top: 0px;
  }
  &:hover,
  &:focus {
    background-color: transparent;
    color: #ed5565;
    border-color: #ed5565;
  }
  &:hover svg path,
  &:focus svg path {
    fill: #ed5565 !important;
  }
}
.btnCalc {
  background-color: #26417b;
  width: auto;
  height: 35px;
  padding: 8px 16px;
  margin: 0px;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: transparent !important;
  color: #ffffff;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  &:disabled {
    background-color: #26417b !important;
    color: #ffffff;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    position: relative;
    top: 0px;
  }
  &::after {
    display: none !important;
  }
  &:hover,
  &:focus {
    background-color: #26417b !important;
    color: #ffffff !important;
    border-color: #26417b !important;
    outline: 2px solid #2ea9fb !important;
  }
}
.btnAddInputs {
  background-color: #26417b;
  color: white;
  width: auto;
  height: 32px;
  padding-left: 22px;
  padding-right: 22px;
  margin: 0px;
  margin-right: 15px;
  line-height: 1.2em;
  text-transform: uppercase;
  outline: none !important;
  svg {
    width: 13px;
    margin-right: 6px;
    position: relative;
    top: 0px;
  }
  &:hover,
  &:focus {
    background-color: transparent;
    color: #26417b;
    border-color: #26417b;
  }
  &:hover svg path,
  &:focus svg path {
    fill: #26417b !important;
  }
}

.dropdown-select-upload-proposta-credito {
  .ant-select-item-option {
    padding: 7px 12px;
    .ant-select-item-option-content {
      white-space: normal;
      font-size: 12px;
      line-height: 1.25em;
    }
  }
}

@media (max-width: 1305px) {
  .col-input-agencia {
    div:nth-child(2) {
      padding: 0px;
    }
    div:nth-child(3) {
      padding-left: 0px;
    }
  }
}

.ant-select-disabled.selectTipoTelefone {
  .ant-select-selector {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: #fff !important;
    cursor: default !important;
    input {
      cursor: default !important;
    }
  }
}

@media (max-width: 1220px) {
  .col-tabs .col-input-agencia {
    .traco-agencia-digito {
      position: absolute;
    }
    .form-row [class*='col-'] {
      padding: 0px;
      &:first-child {
        padding-left: 5px;
      }
      &:last-child {
        padding-left: 0px;
        padding-right: 5px;
      }
    }
  }
}

.dados-adicionais-convenio {
  border-bottom: 1px solid #d9d9d9;
}

.input-dinamicos {
  .form-row {
    margin: 0 -5px;
  }
  .btnRemoveInputs {
    background-color: #dc3545;
    color: white;
    width: auto;
    height: 32px;
    padding-left: 22px;
    padding-right: 22px;
    margin-top: 21px;
    text-transform: uppercase;
    outline: none !important;
    svg {
      width: 13px;
      margin-right: 6px;
      position: relative;
      top: -2px;
    }
    &:hover,
    &:focus {
      color: #dc3545;
      background-color: #fff;
      border-color: #dc3545;
    }
    &:hover svg path,
    &:focus svg path {
      fill: #dc3545 !important;
    }
  }
  .btnIcon {
    padding: 1px 12px;
  }
}

.btnIcon {
  padding: 1px 12px;
  margin-right: 0;
}

.campo-alterado {
  .ant-input {
    border: 2px solid #2a8bff;
    background: #f4f9ff;
  }
  .ant-picker {
    border: 2px solid #2a8bff;
    background: #f4f9ff;
  }
  .ant-select-selector {
    border: 2px solid #2a8bff !important;
    background: #f4f9ff !important;
  }
  .select-banco {
    border: 2px solid #2a8bff !important;
    background: #f4f9ff !important;
  }
  .ant-picker .ant-picker-focused input {
    border: 2px solid #2a8bff !important;
    background: #f4f9ff !important;
  }
  .ant-picker .ant-picker-focused {
    border: 2px solid #2a8bff !important;
    background: #f4f9ff !important;
  }
  .ant-input-number-input {
    border: 2px solid #2a8bff !important;
    background: #f4f9ff !important;
  }
  .ant-input-number-input-wrap {
    border: 2px solid #2a8bff !important;
    background: #f4f9ff !important;
  }
  .ant-picker .ant-form-item-control-input {
    border: 2px solid #2a8bff !important;
    background: #f4f9ff !important;
  }
}

.campo-existente {
  .ant-input {
    border: 2px solid #ad5ad3;
    background: #f2d6ff;
  }
  .ant-picker {
    border: 2px solid #ad5ad3;
    background: #f2d6ff;
  }
  .ant-select-selector {
    border: 2px solid #ad5ad3 !important;
    background: #f2d6ff !important;
  }
  .select-banco {
    border: 2px solid #ad5ad3 !important;
    background: #f2d6ff !important;
  }
  .ant-picker .ant-picker-focused input {
    border: 2px solid #ad5ad3 !important;
    background: #f2d6ff !important;
  }
  .ant-picker .ant-picker-focused {
    border: 2px solid #ad5ad3 !important;
    background: #f2d6ff !important;
  }
  .ant-input-number-input {
    border: 2px solid #ad5ad3 !important;
    background: #f2d6ff !important;
  }
  .ant-input-number-input-wrap {
    border: 2px solid #ad5ad3 !important;
    background: #f2d6ff !important;
  }
  .ant-picker .ant-form-item-control-input {
    border: 2px solid #ad5ad3 !important;
    background: #f2d6ff !important;
  }
}
.icone-campo-alterado {
  color: #2a8bff;
  margin-left: 5px;
}

.check-campo {
  margin-left: 5px !important;
}

.col-tabs .ant-checkbox-inner {
  width: 10px !important;
  height: 11px !important;
  &::after {
    border: 1px solid #fff;
    border-top: 0;
    border-left: 0;
    width: 3px;
    height: 5px;
  }
}

.titleResumo {
  color: #26417b;
  font-weight: 600;
  font-size: 13px;
}

.resumo-contato {
  overflow: hidden;
  overflow-y: scroll;
  height: 550px;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: #26417b;
    border-radius: 5px;
  }

  .ant-input[disabled],
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.75) !important;
  }
}

.elegivel {
  path {
    fill: #1ab394;
  }
}

.comprovante {
  path {
    fill: #ff964a;
  }
}

.nao-elegivel {
  path {
    fill: #ff8f8f;
  }
}

h5.info-status {
  display: inline-block;
  margin: 0px;
  color: #26417b;
  font-size: 14px;
  text-align: left;
  // width: 200px;
}

.info-status {
  svg {
    height: 15px;
    position: relative;
    top: -2px;
  }
}

.preview-analise-mesa-credito {
  .ant-modal-content {
    padding: 25px 55px 25px 25px !important;
    max-height: 100% !important;
    overflow-y: hidden !important;
  }
  .ant-modal-body {
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow-y: hidden !important;
  }
  object {
    max-height: 100%;
    top: 0;
    position: absolute;
    margin: 0 !important;
  }
  .file-preview-container {
    max-height: 100% !important;
    position: relative;
    overflow-y: hidden !important;
    padding: 0 !important;
  }
}

.modal-preview-image {
  overflow: auto;
  max-height: 100%;
}

.com-titulo {
  .ant-modal-content {
    padding: 25px 25px 81px 25px !important;
  }
}

.tooltip-calc-operacao-mesa-credito {
  .ant-tooltip-arrow {
    width: 25px;
    height: 25px;
    left: -16px;
    .ant-tooltip-arrow-content {
      width: 30px;
      height: 30px;
      right: -30px;
    }
  }
  .ant-tooltip-inner {
    padding: 0;
    min-height: 0;
    .tooltip-content {
      background: #ffffff !important;
      border: 0.8px solid #9baebf;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;

      .ibox {
        margin: 0;
        .ibox-title {
          padding: 21px 16px 15px 18px !important;
          .title-label {
            h3 {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 13px !important;
              line-height: 20px;
              color: #26417b;
              margin-bottom: 6px;
              margin-top: 0;
            }
            .title-line {
              width: 20px;
              height: 1px;
            }
          }
          .icon-close-tooltip {
            width: 16px;
            height: 16px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .ibox-content {
          padding: 0 16px 23px 18px;
          .col-buttons-calc {
            margin-top: 8px;
            .btn-confirm {
              margin-right: 8px;
              background-color: #e7eaec;
              border: 1px solid #cfd2d4;
              &:hover {
                outline: 2px solid #2ea9fb !important;
              }
            }
          }
          .ant-form-item {
            margin-bottom: 15px;
            .ant-form-item-label {
              padding: 0;
              label {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #686b6d !important;
              }
            }
            .ant-input.prestacao-max {
              background-color: #fa8b3c;
              border-radius: 5px;
              border: 1px solid #fa8b3c;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
.tooltip-info {
  .ant-tooltip-inner {
    position: relative;
    top: 0px;
    right: 40%;
    margin-left: 5px;
    font-size: 13px;
  }
}

.titulo-bloco {
  color: #26417b;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}


  .card-icon-title {
    margin-bottom: 16px;
  }

  .itens-crivo {
    margin-bottom: 15px;

    ul {
      list-style: none;
      padding-left: 0px;

      li {
        width: 100%;
        line-height: 1.1em;
        font-size: 13.3px;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #686B6D;

        .ellipse {
          margin-right: 5px;
          width: 11px !important;
          height: 11px !important;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .title-interna{
    display: flex;
    padding: 6px 15px;
    background-color: #ffffff;
    color: #263659;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 700;
    align-items: center;
    border: 1px solid #D9D9D9;
    border-bottom: none;

    svg {
      path {
        fill: #263659 !important;
      }
    }
  }
  .charts-info {
    padding-top: 15px;
    border-top: 2px solid #d9d9d9;
    width: 95%;

    .col-chart {
      padding: 0 5px !important;
      width: 48%;

      .card-chart {
        padding: 5px;
        padding-bottom: 7px;

        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
        border-radius: 3px;

        .card-chart-title {
          font-size: 13px;
          line-height: 1.2em;
        }
        .card-chart-subtitle {
          font-size: 11.5px;
          b {
            margin: 0px !important;
          }
        }
      }
    }

    .card-chat-info-proc-crivo {
      color: #686B6D;
    }

    .slick-slider {
      position: relative;

      .slick-arrow {
        transform: translateY(-50%);

        svg {
          path {
            fill: #ededed !important;
            stroke: #ededed;
            stroke-width: 100px;
            stroke-linejoin: round;
          }
        }
        &:hover {
          svg {
            path {
              fill: #cccccc !important;
              stroke: #cccccc;
            }
          }
        }
      }

      .slick-list {
        overflow: hidden;
        padding-bottom: 10px;
        .slick-slide {
          width: 50%;
          display: inline-block;
        }
      }
      .slick-dots {
        list-style: none;
        width: 100%;
        text-align: center;
        margin: 0px;
        padding: 0px;
        position: relative;
        top: -4px;

        li {
          display: inline-block;
          line-height: 1em;
          margin: 0 3.5px;

          button {
            background-color: #f0f0f0;
            color: #f0f0f0;
            width: 10px !important;
            height: 10px !important;
            display: inline-block;
            border-radius: 50%;
            cursor: pointer;
            border: none !important;
            outline: none !important;
            font-size: 3px;
            padding: 0px;
          }
          &.slick-active {
            button {
              background-color: #c2c2c2;
              color: #c2c2c2;
            }
          }
        }
      }
    }
  }


