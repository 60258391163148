.ibox-conversation-messages {
  .ibox-content {
    height: calc(100% - 55px) !important;
  }
}

.mensagens {
  margin: 0 -7px;
  height: 100%;

  .mensagem-content {
    margin-bottom: 20px;
  }

  .list-mensagens {
    height: calc(100% - 145px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 8px;
    position: relative;
    margin-bottom: 5px;

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar {
      width: 3px;
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #ff7817;
      border-radius: 5px;
    }
  }

  .item-mensagem {
    padding-right: 17px;
    padding-left: 17px;
    margin-bottom: 5px;

    .info-autor {
      text-align: left;
      padding-right: 5px;
      padding-left: 10px;
      position: relative;

      .data-hora {
        position: relative;
        top: 55%;
        transform: translateY(-50%);

        p:first-child {
          margin: 0px;
          margin-bottom: 3px;
          line-height: 1em;
          font-size: 12px;
          color: rgba(103, 106, 108, 0.75) !important;
        }
        p.horario {
          font-size: 12px;
          color: #ff7817 !important;
          line-height: 1em;
          font-style: italic;
          margin: 0px;
          margin-bottom: 5px;
        }
        p.data {
          line-height: 1.3em;
          color: rgba(103, 106, 108, 0.75) !important;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
    .texto-mensagem {
      padding-left: 10px;
      padding-right: 2px;
      padding-bottom: 5px;
      text-align: right;

      .topo {
        direction: rtl;

        .icon-perfil {
          width: 25px;
          height: 25px;
          background-color: #e7eaec;
          border: 1px solid #dcdcdc;
          display: inline-block;
          position: relative;
          bottom: -6px;
          margin-left: 6px;
          margin-right: 0px;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        h4 {
          color: #686b6d !important;
          font-size: 14px;
          margin: 0px;
          margin-bottom: 12px;
          display: inline-block;
        }
        .novo-fluxo {
          h4 {
            width: 80%;
            vertical-align: middle;
          }
        }
      }
      p {
        color: #686b6d !important;
        font-size: 13px;
        line-height: 1.35em;
        margin: 0px;
        padding-left: 1px;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }

    &.msg-empresa .texto-mensagem {
      text-align: left;
    }

    &.msg-empresa {
      p {
        hr {
          width: 20%;
          margin: 5px;
          background-color: #dcdcdc;
          border: 1px solid #dcdcdc;
          border-radius: 10px;
        }
      }

      span {
        font-weight: bolder;
      }

      direction: rtl;

      .info-autor {
        text-align: right;
      }
      .texto-mensagem {
        direction: initial;
        margin-bottom: -5px;

        h4 {
          color: #26417b !important;
        }
      }
      .icon-perfil {
        margin-left: 0px !important;
        margin-right: 6px !important;
        background-color: #26417b !important;
        svg path {
          fill: #fa6e0a !important;
        }
        &.anotacoes {
          background-color: #dcdcdc !important;
          svg path {
            fill: #686b6d !important;
          }
        }
        }
    }
  }

  .form-row-mensagem {
    padding-right: 0px;
    padding-left: 5px;
    margin-top: -1px;

    div {
      padding-right: 0px;
      padding-left: 0px;
    }

    textarea {
      border: 1px solid #dcdcdc;
      margin-left: -3px;
      height: 95px;
      padding: 7px 9px;
      color: #a4a7ab;
      font-size: 13px;
      line-height: 1.3em;
      resize: none;
      outline: none !important;
      box-shadow: none;
    }

    .btn-acao {
      width: 100%;
      height: 35px;
      margin-left: -3px;
      margin-top: 15px;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      outline: none !important;
      color: white;

      svg {
        margin-right: 10px;

        path {
          fill: #fff !important;
        }
      }
    }

    .btn-acao.btn-enviar {
      background-color: #26417b;
      &:hover,
      &:focus {
        color: #26417b;
        background-color: transparent;
        border-color: #26417b;

        svg path {
          fill: #26417b !important;
        }
      }
    }

    .btn-acao.btn-salvar {
      background-color: #1ab394;
      &:hover,
      &:focus {
        color: #1ab394;
        background-color: transparent;
        border-color: #1ab394;

        svg path {
          fill: #1ab394 !important;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.card-tooltip-messages {
  max-height: calc(80vh);
  width: 350px;
  max-width: 350px;

  &.card-fixed {
    position: fixed;
  }

  .ant-tooltip-content {
    width: 347px;
    max-height: calc(80vh);
    position: relative;
    padding-bottom: 7px;

    .ant-tooltip-inner {
      padding: 0px;
      background-color: #fff;
      width: 347px;
      max-height: calc(80vh);
      border: 1px solid #dbdbdb;
      box-shadow: 5px -2px 4px rgba(0, 0, 0, 0.3),
        -5px 5px 5px rgba(0, 0, 0, 0.3);

      .ibox {
        padding: 0px;
        margin: 0px;
        max-height: calc(80vh);

        .title-label {
          max-width: 350px;

          h3 {
            font-size: 16px;
          }
          .title-line {
            display: none;
          }
        }

        .ibox-content {
          padding-left: 5px;
          padding-right: 10px;
          height: calc(80vh - 4em) !important;
          padding-top: 10px;
          padding-bottom: 0px;
        }
        .ibox-title {
          height: 2.7em;
          padding-bottom: 0px;
        }

        .close-tooltip {
          display: inline-block;
          position: absolute;
          width: 10px;
          right: 10px;
          top: 5px;
          border: none;
          padding: 0px;
        }
        .close-tooltip:hover {
          opacity: 0.7;
        }
        .close-tooltip:hover,
        .close-tooltip:focus {
          outline: none !important;
        }

        .mensagens {
          padding-right: 5px;
          padding-left: 15px;

          .list-mensagens {
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: 15px;
            position: relative;
            height: calc(80vh - 14em);
          }

          .item-mensagem {
            padding: 0px;
            padding-left: 7px;
            padding-right: 7px;
          }
          .form-row-mensagem {
            padding-right: 0px;
            padding-left: 0px;
            height: 10.5em;

            textarea {
              margin-left: -3px;
              margin-right: -3px;
              height: 80px;
            }
            .btn-acao {
              margin-top: 5px;
              margin-left: -2px;
            }
          }
        }
      }
    }
  }
}

.card-tooltip-messages.ant-tooltip-placement-top .ant-tooltip-arrow,
.card-tooltip-messages.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.card-tooltip-messages.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -7px;
}
.card-tooltip-messages.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.card-tooltip-messages.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.card-tooltip-messages.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -13px;
}
